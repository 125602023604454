import * as React from 'react'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import CmsPageSectionRenderer from '../section-renderer/page'

const CmsPage = ({ data }) => {
  return <CmsPageSectionRenderer data={data} />
}

// proptypes for the page
CmsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      publishedAt: PropTypes.any,
      updatedAt: PropTypes.any,
      url: PropTypes.string,
      seo: Seo.propTypes.seo,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          heading: PropTypes.string,
          content: PropTypes.string,
          videos: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              url: PropTypes.string,
            })
          ),
          strapi_component: PropTypes.string,
        })
      ),
    }),
  }),
}

export const query = graphql`
  query CmsPageQuery($pageId: Int!) {
    page: strapiPage(strapi_id: { eq: $pageId }) {
      title
      content
      url: urlPath
      # createdAt
      publishedAt
      updatedAt
      table_of_contents
      seo {
        ...SEO
      }
      sections {
        ... on STRAPI__COMPONENT_SECTION_IMAGE_MOSAIC {
          ...ImageMosaicSection
        }
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH_WITH_STICKY_IMAGE {
          ...ParagraphSectionWithStickyImage
        }
        ... on STRAPI__COMPONENT_SECTION_PRODUCT_GRID {
          ...ProductGridSection
        }
        ... on STRAPI__COMPONENT_SECTION_TAGGED_LANDING_PAGES {
          ...TaggedLandingPages
        }
        ... on STRAPI__COMPONENT_SECTION_CONTACT_US_MAP {
          ...MapSection
        }
        ... on STRAPI__COMPONENT_SECTION_NUMBERED_LIST {
          ...NumberedListSection
        }
        ... on STRAPI__COMPONENT_SECTION_COMMON_QUESTIONS {
          ...HaveQuestionsSection
        }
        ... on STRAPI__COMPONENT_SECTION_SECTION_LAYOUT {
          ...SectionLayout
        }
        ... on STRAPI__COMPONENT_SECTION_YOUTUBE_VIDEOS {
          ...YoutubeSection
        }
        ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
          ...ImageSection
        }
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
        ... on STRAPI__COMPONENT_SECTION_FORM {
          ...FormSection
        }
      }
    }
  }
`
export default CmsPage
